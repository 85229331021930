import { Component, enableProdMode,OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FuncionesService } from './funciones.service';
import {Globals} from './globales';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs/Observable';
defineLocale('es', esLocale);
enableProdMode();

class Propiedad {
  dato=null;
  moneda=null;
  ciudad=null;
  barrio=null;
  ordenar='';
  operacion=null;
  tipo=null;
  tipo_barrio=null;
  lavadero=false;
  living_comedor=null;
  dependencia_servicio=false;
  cocina=null;
  terraza=false;
  pileta=false;
  parrilla=false;
  vigilancia=false;
  ascensor=false;
  cancha_deportes=false;
  gimnasio=false;
  lavanderia=false;
  quincho=false;
  solarium=false;
  sum=false;
  apto_credito=false;
  mascotas=null;
  luz=false;
  agua=false;
  gas=null;
  vigente=true;
  calefaccion=null;
  aire=null;
  atributos=[];
  detalles=[];
  constructor(item=null) {
    this.dato=item;
  }
}

export interface DialogData {
  texto: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss','./bootstrap.css']
})
export class AppComponent implements OnInit {
  token: string | null = null;
  pantalla='';
  filtro_operacion=null;
  filtro_tipo=null;
  filtro_barrio=null;
  ciudades=[];
  propiedades=[];
  barrios_todos=[];
  viendo:Propiedad=null;
  fotos=[];
  
  G: Globals;
  constructor(private http: HttpClient
    ,G: Globals
    ,public Funcion: FuncionesService
    ,private bsLocaleService: BsLocaleService
    ,public Confirmar: MatDialog
    ,private route: ActivatedRoute
    ) {
    this.bsLocaleService.use('es');
    this.G=G;
    this.G.URL='http://localhost/garayzabal/remoto/';
    this.G.appVersion='Versión 1.0.0';
    this.G.ModoProduccion=environment.production;
    this.getJSON().subscribe(data => {
      this.G.URL=data.url;
      this.G.empresa=data.empresa;
      this.G.ModoProduccion=data.modo;
      console.log(this.G);
      this.f_inicializar();
    });
  }
  public getJSON(): Observable<any> {
    return this.http.get('assets/config.json');
  }
  onInit() {
  }
  
  ngOnInit() {
    this.G.ancho = window.innerWidth;
    this.G.alto = window.innerHeight;
    this.G.modo_celular=(this.G.ancho<500);
    this.route.queryParamMap.subscribe(params => {
      this.token = params.get('token');
    });
  }
  cambia_ancho(event) {
    this.G.ancho = event.target.innerWidth;
    this.G.alto = event.target.innerHeight;
    this.G.modo_celular=(this.G.ancho<500);
  }
  f_acomodar_barrios(ciudades) {
    this.ciudades=ciudades;
    this.barrios_todos=[];
    this.barrios_todos.push({barrio_id:0,nombre:'--- CUALQUIERA ---'});
    for (let c of this.ciudades) {
      for (let b of c.barrios) {
        b.nombre=b.barrio_nombre+' - '+c.nombre;
        this.barrios_todos.push(b);
      }
    }
    if (this.filtro_barrio===null) this.filtro_barrio=this.barrios_todos[0];
  }
  f_inicializar() {
    this.filtro_tipo=this.G.propiedades_tipos[0];
    this.filtro_operacion=this.G.propiedades_operaciones[0];
    this.filtro_barrio=this.G.propiedades_barrios[0];
    this.http.post(this.G.URL+'__inmobiliaria.php', {
      accion:'datos_iniciales',login_empresa: this.G.empresa}).subscribe(
        data => {
          this.f_acomodar_barrios(data['listado']);
          if (this.token!==null && this.token!=='') this.f_buscar(this.token);
        },(error: any) => { console.log(error); }
    );

  }
  f_buscar(token='') {
    let voperacion=0,vtipo=0,vbarrio=0;
    if (token=='') {
      voperacion=this.filtro_operacion.id;
      vtipo=this.filtro_tipo.id;
      vbarrio=this.filtro_barrio.barrio_id;
    }
    this.Funcion.show_loading(1);
    this.http.post(this.G.URL+'__inmobiliaria.php', {
      accion:'consultar',
      token:token,
      operacion:voperacion,
      tipo:vtipo,
      barrio:vbarrio,
      vigente:1,
      login_empresa: this.G.empresa}).subscribe(
        data => {
          this.Funcion.show_loading(0);
          this.f_acomodar_barrios(data['datos']);
          this.propiedades=[];
          let p:Propiedad;
          for (let m of data['listado']) {
            p=new Propiedad(m);
            p.moneda=this.Funcion.buscar_por_id(this.G.monedas_seleccionables,p.dato.moneda);
            p.operacion=this.Funcion.buscar_por_id(this.G.propiedades_operaciones,p.dato.operacion);
            p.tipo=this.Funcion.buscar_por_id(this.G.propiedades_tipos,p.dato.tipo);
            p.tipo_barrio=this.Funcion.buscar_por_id(this.G.propiedades_barrios,p.dato.tipo_barrio);
            p.living_comedor=this.Funcion.buscar_por_id(this.G.propiedades_living_comedor,p.dato.living_comedor);
            p.lavadero=(p.dato.lavadero===1);
            p.dependencia_servicio=(p.dato.dependencia_servicio===1);
            p.cocina=this.Funcion.buscar_por_id(this.G.propiedades_cocina,p.dato.cocina);
            p.terraza=(p.dato.terraza===1);
            p.pileta=(p.dato.pileta===1);
            p.parrilla=(p.dato.parrilla===1);
            p.vigilancia=(p.dato.vigilancia===1);
            p.ascensor=(p.dato.ascensor===1);
            p.cancha_deportes=(p.dato.cancha_deportes===1);
            p.gimnasio=(p.dato.gimnasio===1);
            p.lavanderia=(p.dato.lavanderia===1);
            p.quincho=(p.dato.quincho===1);
            p.solarium=(p.dato.solarium===1);
            p.sum=(p.dato.sum===1);
            p.apto_credito=(p.dato.apto_credito===1);
            p.luz=(p.dato.luz===1);
            p.agua=(p.dato.agua===1);
            p.vigente=(p.dato.vigente===1);
            p.gas=this.Funcion.buscar_por_id(this.G.propiedades_gas,p.dato.gas);
            p.calefaccion=this.Funcion.buscar_por_id(this.G.propiedades_calefaccion,p.dato.calefaccion);
            p.aire=this.Funcion.buscar_por_id(this.G.propiedades_aire,p.dato.aire);
            p.mascotas=this.Funcion.buscar_por_id(this.G.propiedades_mascotas,p.dato.mascotas);
            for (let c of this.ciudades) {
              for (let b of c.barrios) {
                if (b.barrio_id===p.dato.barrio) {
                  p.ciudad=c;
                  p.barrio=b;
                }
              }
            }
            p.detalles.push({nombre:'Precio:',valor:p.moneda.simbolo+' '+this.Funcion.formatNumber(p.dato.precio,0)});
            p.detalles.push({nombre:'Tipo de Propiedad:',valor:p.tipo.nombre});
            p.detalles.push({nombre:'Operación:',valor:p.operacion.nombre});
            if (p.apto_credito) p.detalles.push({nombre:'Apto Crédito',valor:''});
            p.detalles.push({nombre:'Barrio Tipo:',valor:p.tipo_barrio.nombre});
            p.detalles.push({nombre:'Superficie:',valor:this.Funcion.formatNumber(p.dato.m2,0)+' m2'});
            if (p.dato.m2_construido) p.detalles.push({nombre:'Cubierto:',valor:this.Funcion.formatNumber(p.dato.m2_construido,0)+' m2'});
            if (p.dato.ambientes>0) p.detalles.push({nombre:'Ambientes Total:',valor:this.Funcion.formatNumber(p.dato.ambientes,0)});
            if (p.dato.plantas>1) p.detalles.push({nombre:'Plantas:',valor:this.Funcion.formatNumber(p.dato.plantas,0)});

            if (p.dato.dormitorios===1 && p.dato.dormitorios_suite===0) p.atributos.push('1 Dormitorio');
            if (p.dato.dormitorios===1 && p.dato.dormitorios_suite===1) p.atributos.push('1 Dormitorio en suite');
            if (p.dato.dormitorios>1 && p.dato.dormitorios_suite===p.dato.dormitorios) p.atributos.push(p.dato.dormitorios+' Dormitorios en suite');
            if (p.dato.dormitorios>1 && p.dato.dormitorios_suite<p.dato.dormitorios) p.atributos.push(p.dato.dormitorios+' Dormitorios');
            if (p.dato.dormitorios_suite===1 && p.dato.dormitorios_suite!==p.dato.dormitorios) p.atributos.push('1 Dormitorio en suite');
            if (p.dato.dormitorios_suite>1 && p.dato.dormitorios_suite!==p.dato.dormitorios) p.atributos.push(p.dato.dormitorios_suite+' Dormitorios en suite');
            if (p.dato.banios===1) p.atributos.push('1 Baño');
            if (p.dato.banios>1) p.atributos.push(p.dato.banios+' Baños');
            if (p.dato.toilete===1) p.atributos.push('1 Toilete');
            if (p.dato.toilete>1) p.atributos.push(p.dato.toilete+' Toiletes');
            if (p.dato.cocina>0) p.atributos.push(p.cocina.nombre);
            if (p.dato.living_comedor.id>0) p.atributos.push(p.living_comedor.nombre);
            if (p.dato.cochera===1) p.atributos.push('1 Vehículo');
            if (p.dato.cochera>1) p.atributos.push(p.dato.cochera+' Vehículos');
            if (p.dependencia_servicio) p.atributos.push('Dependencia de Servicio');
            if (p.quincho) p.atributos.push('Quincho');
            if (p.terraza) p.atributos.push('Terraza');
            if (p.pileta) p.atributos.push('Pileta');
            if (p.parrilla) p.atributos.push('Parrilla');
            if (p.dato.calefaccion>0) p.atributos.push(p.calefaccion.nombre);
            if (p.dato.aire>0) p.atributos.push(p.aire.nombre);
            if (p.agua) p.atributos.push('Agua Corriente');
            if (p.luz) p.atributos.push('Electricidad');
            if (p.dato.gas>0) p.atributos.push(p.gas.nombre);
            if (p.vigilancia) p.atributos.push('Vigilancia');
            if (p.ascensor) p.atributos.push('Ascensor');
            if (p.cancha_deportes) p.atributos.push('Deportes');
            if (p.gimnasio) p.atributos.push('Gimnasio');
            if (p.lavanderia) p.atributos.push('Lavandería');
            if (p.solarium) p.atributos.push('Solarium');
            if (p.sum) p.atributos.push('S.U.M.');
            if (p.dato.mascotas>0) p.atributos.push(p.mascotas.nombre);
         

            p.ordenar=p.dato.fecha+this.Funcion.formato_numero(p.dato.propiedad_id);
            this.propiedades.push(p);
          }
          this.Funcion.f_ordenar(this.propiedades,'ordenar');
          this.pantalla='listado';
        },(error: any) => { console.log(error); }
    );
  }
  f_mostrar_propiedad(item) {
    this.Funcion.show_loading(1);
    this.http.post(this.G.URL+'__inmobiliaria.php', {
      accion:'traer_fotos',
      id:item.dato.propiedad_id,
      login_empresa: this.G.empresa}).subscribe(
        data => {
          this.Funcion.show_loading(0);
          this.fotos=data['listado'];
          this.viendo=item;
          this.pantalla='viendo_una';
        },(error: any) => { console.log(error); }
    );
  }
  f_ancho_viendo_uno(inicial=800) {
    let ancho=inicial;
    if (this.G.ancho-20<inicial) {
      ancho=this.G.ancho-20;
    }
    return ancho;
  }
}

<div class="ocultar" *ngIf="inicializado"></div>
<div class="viendo-fotos-fondo" *ngIf="inicializado">
    <img [src]="imagenes[viendo_fotos_indice].imagen_data">
</div>
<div class="viendo-fotos-contenedor" *ngIf="inicializado">
    <div class="viendo-fotos-cerrar">
        <img class="derecha" src="assets/cancelar.svg" (click)="f_cerrar()">
    </div>
    <div class="viendo-fotos-flecha" style="left:10px;" *ngIf="viendo_fotos_indice>0" (click)="viendo_fotos_indice=viendo_fotos_indice-1" [style.top.px]="(G.alto-viendo_fotos_alto_fotos_mini)/2">
        <img src="assets/chevron-left.svg">
    </div>
    <div class="viendo-fotos-flecha" style="right:10px;" *ngIf="viendo_fotos_indice<(imagenes.length-1) && imagenes.length>1" (click)="viendo_fotos_indice=viendo_fotos_indice+1;" [style.top.px]="(G.alto-viendo_fotos_alto_fotos_mini)/2">
        <img src="assets/chevron-right.svg">
    </div>
    <div class="viendo-fotos-contenedor-viendo">
        <img class="viendo-fotos-viendo" [src]="imagenes[viendo_fotos_indice].imagen_data" [style.width.px]="f_imagen_ancho_alto(1)" [style.height.px]="f_imagen_ancho_alto(2)">
    </div>
    <div class="viendo-fotos-abajo" *ngIf="imagenes.length>1" [style.height.px]="viendo_fotos_alto_fotos_mini">
        <img *ngFor="let item of imagenes; let i = index" [attr.data-index]="i" [src]="item.imagen_data" (click)="viendo_fotos_indice=i" [ngClass]="{'seleccionado':i===viendo_fotos_indice}">
    </div>
</div>

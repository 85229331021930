import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent {
  @Input() imageUrl: string = '';
  @Input() precio: string = '';
  @Input() direccion: string = '';
  @Input() ciudad: string = '';
  @Input() barrio: string = '';
  @Input() tipo: string = '';
  @Input() m2: number = 0;
  @Input() m2_construido: number = 0;
  @Input() ambientes: number = 0;
  @Input() dormitorios: number = 0;
  @Input() banios: number = 0;
}
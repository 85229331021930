<div class="product-card">
    <div class="image-container">
      <img [src]="imageUrl" alt="Product Image" />
      <span class="price-tag">{{precio}}</span>
      <span class="tipo-tag">{{tipo}}</span>
    </div>
    <div class="description">
      <p>{{ direccion }}, {{barrio}}, {{ciudad}}</p>
    </div>
    <div class="caracteristicas">
      <p>
        <span>Sup. <b>{{ m2 }} m²</b></span>
        <span *ngIf="m2_construido > 0"> / {{ m2_construido }} m² construido</span>
      </p>
      <p *ngIf="ambientes>0">
        <span>Ambientes: <b>{{ ambientes }}</b></span>
        <span> / Dormitorios: <b>{{ ambientes }}</b></span>
      </p>
      <p *ngIf="banios > 0">Baños: <b>{{ banios }}</b></p>
    </div>

  </div>
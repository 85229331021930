import { Injectable, EventEmitter } from '@angular/core';
import { Globals } from './globales';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class FuncionesService {

  Confirmar$=new EventEmitter<number>();

  G: Globals;
  constructor(
    private http: HttpClient
    ,variables: Globals
    ,private Dialogo: MatDialog) {
    this.G=variables;
  }
  Alerta(mensaje='',tipo=0) {
    this.G.alerta_ver=true;
    this.G.alerta_texto=mensaje;
    this.G.alerta_tipo=tipo;
  }
  Confirmar(mensaje='',tipo?: number,opciones?: any) {
    if (tipo===null || tipo===undefined) { tipo=1; }
    this.G.confirmar_tipo=tipo;
    this.G.confirmar_mensaje=mensaje;
    if (tipo===1) {
      this.G.confirmar_opciones=[{valor:1,mensaje:'Aceptar',tipo:1},{valor:0,mensaje:'Cancelar',tipo:2}];
    } else if (tipo===2) {
      this.G.confirmar_opciones=[{valor:1,mensaje:'ELIMINAR',tipo:2},{valor:2,mensaje:'ANULAR',tipo:2},{valor:0,mensaje:'Regresar',tipo:1}];
    } else if (tipo===0) {
      this.G.confirmar_opciones=opciones;
    }
    this.G.confirmar_ver=true;
  }
  show_loading(modo: number) {
    setTimeout (() => this.show_loading_cambia (modo), 0);
  }
  show_loading_cambia(modo: number) {
    if (modo===0) {
      this.G.div_esperando=false;
    } else {
      this.G.div_esperando=true;
    }
  }
  buscar_por_id(varray,valor) {
    let a: any;
    for (a=0;a<varray.length;a++) {
      if (varray[a].id===valor) {
        return varray[a];
      }
    }
    return [];
  }
  eliminar_array_id(varray,valor) {
    let a: any;
    for (a=0;a<varray.length;a++) {
      if (varray[a].id===valor) {
        varray.splice(a,1);
      }
    }
    return varray;
  }
  es_numero(pnumero,minimo=null,maximo=null) {
    if (isNaN(pnumero)) {
      return false;
    } else if (pnumero===null || pnumero===undefined) {
      return false;
    } else {
      let retorna=true;
      if (minimo!==null && pnumero<minimo) {
        retorna=false;
      } else if (maximo!==null && pnumero>maximo) {
        retorna=false;
      }
      return retorna;
    }
  }
  fecha_hoy() {
    const f=new Date();
    const f2=new DatePipe('en-Us').transform(f, 'yyyy-MM-dd',this.G.uso_horario);
    return this.mysql_a_formatofecha(f2);
  }
  mes_hoy() {
    const f=new Date();
    return f.getMonth()+1;
  }
  anio_hoy() {
    const f=new Date();
    return f.getFullYear();
  }
  fecha_a_mysql(f,con_guiones=true,con_horario=false) {
    let ret='';
    let vm: any; let vd: any;  let vy,vhora,vmin,vseg: any;  vy=f.getFullYear();	if ((f.getMonth() +1)<10) {	vm='0'+(f.getMonth() +1);	} else {	vm=(f.getMonth() +1);	}	if (f.getDate()<10) {	vd='0'+f.getDate();	} else { vd=f.getDate();	}
    if (f.getHours()<10) { vhora='0'+f.getHours().toString();} else {vhora=f.getHours().toString(); }
    if (f.getMinutes()<10) { vmin='0'+f.getMinutes().toString();} else {vmin=f.getMinutes().toString(); }
    if (f.getSeconds()<10) { vseg='0'+f.getSeconds().toString();} else {vseg=f.getSeconds().toString(); }
    if (con_guiones) {
      ret=vy+'-'+vm+'-'+vd;
      if (con_horario) { ret+=' '+vhora+':'+vmin+':'+vseg;}
    } else {
      ret=vy+vm+vd;
      if (con_horario) { ret+=' '+vhora+vmin+vseg;}
    }
    return ret;
  }

  fecha_a_txt(f) { let vm: any; let vd: any;  let vy: any; vy=f.getFullYear();	if ((f.getMonth() +1)<10) {	vm='0'+(f.getMonth() +1);	} else {	vm=(f.getMonth() +1);	}	if (f.getDate()<10) {	vd='0'+f.getDate();	} else { vd=f.getDate();	}	return vd+'/'+vm+'/'+vy;}
  fecha_desplazada(f,dias) {
    if (dias>0) {
      while(dias>0) {
        f=this.fecha_dia_posterior(f);
        dias--;
      }
    } else {
      while (dias<0) {
        f=this.fecha_dia_anterior(f);
        dias++;
      }
    }
    return f;
  }
  fecha_dia_anterior(f) {
    const vf=this.fecha_a_mysql(f);
    const vdia=parseInt(vf.substring(8,10),10);
    let vmes=parseInt(vf.substring(5,7),10);
    let vanio=parseInt(vf.substring(0,4),10);
    let respuesta=f;
    if (vdia>1) {
      respuesta=new Date(vanio,vmes-1,vdia-1);
    } else {
      if (vmes===0) {vmes=12;vanio--;} else {vmes--;}
      respuesta=this.fecha_ultimo_dia(vmes,vanio);
    }
    return respuesta;
  }
  fecha_dia_posterior(f) {
    const vf=this.fecha_a_mysql(f);
    let vdia=parseInt(vf.substring(8,10),10);
    let vmes=parseInt(vf.substring(5,7),10);
    let vanio=parseInt(vf.substring(0,4),10);
    let respuesta=f;
    if (vdia<28) {
      respuesta=new Date(vanio,vmes-1,vdia+1);
    } else {
      const vf2=new Date(vanio,vmes-1,vdia);
      if (vf2===this.fecha_ultimo_dia(vmes,vanio)) {
        vdia=1;
        if (vmes===12) { vanio++; vmes=1; } else { vmes++; }
      } else {
        vdia++;
      }
      respuesta=new Date(vanio,vmes-1,vdia);
    }
    return respuesta;
  }
  fecha_ultimo_dia(mes,anio) {
    if (mes===1 || mes===3 || mes===5 || mes===7 || mes===8 || mes===10 || mes===12) {
      return new Date(anio,mes-1,31);
    } else if (mes===4 || mes===6 || mes===9 || mes===11) {
      return new Date(anio,mes-1,30);
    } else if (anio/4===this.redondear(anio/4,0)) {
      if (anio/100===this.redondear(anio/100,0)) {
        return new Date(anio,mes-1,28);
      } else {
        return new Date(anio,mes-1,29);
      }
    } else {
      return new Date(anio,mes-1,28);
    }
  }
  formatNumberTxt(num,cantidad_decimales=2) {
    return this.formatNumber(parseFloat(num),cantidad_decimales);
  }
  formatNumber(num,cantidad_decimales=2) {
    let splitLeft: any;
    let splitRight: any;
    if (num===null || num===undefined || !this.es_numero(num) || num===0) {
      splitLeft='0';
      splitRight='';
    } else {
      const separador: any = '.';
      const sepDecimal: any = ',';
      num=this.redondear(num,cantidad_decimales);
      num +='';
      const splitStr = num.split('.');
      splitLeft = splitStr[0];
      splitRight = splitStr.length > 1 ? sepDecimal + splitStr[1] : '';
      const regx = /(\d+)(\d{3})/;
      while (regx.test(splitLeft)) {
        splitLeft = splitLeft.replace(regx, '$1' + separador + '$2');
      }
    }
    let a: number;
    if (splitRight==='' && cantidad_decimales>0) {
      splitRight=',';
      for (a=0;a<cantidad_decimales;a++) {
        splitRight=splitRight+'0';
      }
    } else if (cantidad_decimales>0) {
      for (a=splitRight.length;a<=cantidad_decimales;a++) {
        splitRight=splitRight+'0';
      }
    }
    return splitLeft +splitRight;
  }
  mysql_a_formatofecha(vfecha) {
    return new Date(vfecha.substr(0,4),vfecha.substr(5,2)-1,vfecha.substr(8,2));
  }
  mysql_a_php(vfecha) {
    return vfecha.substr(8,2)+'/'+vfecha.substr(5,2)+'/'+vfecha.substr(0,4);
  }
  php_a_fecha(vfecha) { return this.mysql_a_formatofecha(this.php_a_mysql(vfecha)); }
  php_a_mysql(vfecha) {
    return vfecha.substr(6,4)+'-'+vfecha.substr(3,2)+'-'+vfecha.substr(0,2);
  }
  redondear(valor,decimales=2) { const multiplicar=1*Math.pow(10,decimales); return Math.round(valor*multiplicar)/multiplicar;}
  separar_campos_csv_por_coma(linea) {
    const r = [];
    let i = 0;
    let comilla=false;
    let a: number;
    let datos = '';
    for (a=0;a<linea.length;a++) {
      const caracter=linea.substring(a,a+1);
      if (caracter==='"') {
        if (comilla) {
          comilla=false;
        } else {
          comilla=true;
        }
      } else {
        if (caracter===',') {
          r[i]=datos;
          i++;
          datos='';
        } else {
          datos=datos+caracter;
        }
      }
    }
    r[i]=datos;
    return r;
  }
  separar_campos_csv_por_puntoycoma(linea) {
    const r = [];
    let i = 0;
    let comilla=false;
    let a: number;
    let datos = '';
    for (a=0;a<linea.length;a++) {
      const caracter=linea.substring(a,a+1);
      if (caracter==='"') {
        if (comilla) {
          comilla=false;
        } else {
          comilla=true;
        }
      } else {
        if (caracter===';') {
          r[i]=datos;
          i++;
          datos='';
        } else {
          datos=datos+caracter;
        }
      }
    }
    r[i]=datos;
    return r;
  }
  sumatoria(listado,campo) {
    let total=0;
    let a: number;
    if (listado!==null && listado.length>0) {
      for (a=0;a<listado.length;a++) {
        total+=listado[a][campo];
      }
    }
    return total;
  }
  texto_a_numero(dato) { return parseFloat(dato); }
  validarCuit(pcuit) {
    if (pcuit===null || pcuit===undefined) {
      return false;
    }
    const cuit = pcuit.toString();
    if(cuit.length !== 11) {
      return false;
    }
    let acumulado = 0;
    const digitos = cuit.split('');
    const digito = parseFloat(digitos.pop());
    let i: number;
    for(i = 0; i < digitos.length; i++) {
      acumulado += digitos[9 - i] * (2 + (i % 6));
    }
    let verif = 11 - (acumulado % 11);
    if(verif === 11) {
      verif = 0;
    } else if(verif === 10) {
      verif = 9;
    }
    if (digito === verif) {
      return true;
    } else {
      return false;
    }
  }
  validar_fecha(que) {
    if (Object.prototype.toString.call(que) === '[object Date]') {
      return true;
    } else {
      return false;
    }
  }
  validar_mail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    } else {
      return false;
    }
  }
  validar_numero_mayor_menor(pnumero,pmayor,pmenor) {
    if (isNaN(pnumero)) {
      pnumero=0;
    }
    if (pnumero>pmayor) {
      pnumero=pmayor;
    }
    if (pnumero<pmenor) {
     pnumero=pmenor;
    }
    return pnumero;
  }
  array_buffer_a_texto(buf) {
    return String.fromCharCode.apply(null, new Uint16Array(buf));
  }
  mostrar_edad(edad_anios,edad_meses,edad_dias) {
    let mostrar='';
    if (edad_anios>0) {
      if (edad_anios>1) { mostrar=edad_anios.toString()+' años'; } else { mostrar='1 año'; }
      if (edad_meses>1) { mostrar=mostrar+' y '+edad_meses.toString()+' meses'; } else if (edad_meses===1) { mostrar=mostrar+' y un mes'; }
    } else if (edad_meses>0) {
      if (edad_meses>1) { mostrar=edad_meses.toString()+' meses'; } else if (edad_meses===1) { mostrar='un mes'; }
    } else if (edad_dias>0) {
      if (edad_dias>1) { mostrar=edad_dias.toString()+' días'; } else if (edad_dias===1) { mostrar='un día'; }
    } else {
      mostrar='Recién nacido';
    }
    return mostrar;
  }
  cambio_centro(evento) {
    this.G.gps_mi_posicion= {lat:evento.latLng.lat(), lng:evento.latLng.lng()};
  }
  formato_numero(n,digitos=8) {
    return n.toString().padStart(digitos,'0');
  }
  f_ordenar(listado,campo,ascendiente=true) {
    if (ascendiente) {
      listado.sort((a, b) => {
        if(a[campo] > b[campo]) return 1;
        if(a[campo] < b[campo]) return -1;
        return 0;
      });
    } else {
      listado.sort((a, b) => {
        if(a[campo] < b[campo]) return 1;
        if(a[campo] > b[campo]) return -1;
        return 0;
      });
    }

  }
  fecha_desplazada_mysql(f,dias) {
    return this.fecha_a_mysql(this.fecha_desplazada(this.mysql_a_formatofecha(f),dias));
  }

}

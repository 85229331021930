import { Component, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { Globals } from '../../globales';
import { FuncionesService } from '../../funciones.service';

@Component({
  selector: 'app-ver-fotos',
  templateUrl: './ver-fotos.component.html',
  styleUrls: ['./ver-fotos.component.scss','../../bootstrap.css']
})
export class VerFotosComponent implements OnChanges {
  @Input() imagenes: any;
  @Input() viendo_fotos_indice: number;
  @Output() cerrar = new EventEmitter<any>();
  viendo_fotos_alto_fotos_mini=140;
  inicializado=false;

  G: Globals;
  constructor(variables: Globals
    , public Funcion: FuncionesService
    ) {
    this.G=variables;
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout (() => this.inicializar(), 0);
  }
  f_cerrar() {
    this.cerrar.emit();
  }

  inicializar() {
    if (this.imagenes!==null && this.imagenes!==undefined && this.imagenes.length>0) {
      let a: number;
      const imagen=new Image();
      for (a=0;a<this.imagenes.length;a++) {
          imagen.src=this.imagenes[0].imagen_data;
          this.imagenes[a].ancho=imagen.width;
          this.imagenes[a].alto=imagen.height;
      }
      this.inicializado=true;
    } else {
      this.f_cerrar();
    }
  }
  f_ver_fotos_anchoalgo() {
    const ancho=this.G.ancho-40;
    const alto=this.G.alto-400;
    if (ancho>alto) { return alto; } else { return ancho;}
  }
  f_imagen_ancho_alto(que) {
    const foto_ancho=this.imagenes[this.viendo_fotos_indice].ancho;
    const foto_alto=this.imagenes[this.viendo_fotos_indice].alto;
    let ancho=0;
    let alto=0;
    if (this.G.ancho>=800) {
      /* mayor a 800 habilito los botones a los costados por lo que saco ese espacio, son 50px por boton mas margen de 10, saco en total 120 */
      ancho=this.G.ancho-120;
    } else {
      ancho=this.G.ancho-20;
    }
    /* a la altura le resto la cabecera (60) y el pie para las fotos que no están vistas (80) */
    alto=this.G.alto-this.viendo_fotos_alto_fotos_mini;
    let resultado_ancho=0;
    let resultado_alto=0;
    const porcentaje_ancho=ancho/foto_ancho;
    const porcentaje_alto=alto/foto_alto;
    /* uso el menor porque es el que completa primero lo que sea */
    if (porcentaje_ancho>porcentaje_alto) {
      resultado_alto=alto;
      resultado_ancho=this.Funcion.redondear(foto_ancho*porcentaje_alto,0);
    } else {
      resultado_ancho=ancho;
      resultado_alto=this.Funcion.redondear(foto_alto*porcentaje_ancho,0);
    }
    if (que===1) {
      return resultado_ancho;
    } else {
      return resultado_alto;
    }
  }

}

<div
  class="carousel-container"
  [style.backgroundColor]="backgroundColor"
  (mouseenter)="stopAutoSlide()"
  (mouseleave)="startAutoSlide()"
  [style.width.px]="ancho" [style.height.px]="ancho*.75"
>
  <div class="carousel-slide">
    <img [src]="images[currentIndex]" alt="Carousel Image" />
  </div>
  <button class="prev-button" (click)="prevSlide()">&#10094;</button>
  <button class="next-button" (click)="nextSlide()">&#10095;</button>
  <div class="thumbnails">
    <img
      *ngFor="let image of images; let i = index"
      [src]="image"
      [class.active]="i === currentIndex"
      (click)="goToSlide(i)"
      alt="Thumbnail"
    />
  </div>
</div>

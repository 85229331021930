import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltrandoPipe } from './filtrando.pipe';
import { FiltroIgualdadPipe } from './filtro-igualdad.pipe';
import { FiltroNumericoPipe } from './filtro-numerico.pipe';



@NgModule({
  declarations: [FiltrandoPipe, FiltroIgualdadPipe, FiltroNumericoPipe],
  imports: [
    CommonModule
  ],
  exports: [FiltrandoPipe, FiltroIgualdadPipe, FiltroNumericoPipe]
})
export class TuberiasModule { }

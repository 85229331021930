import { environment } from './../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FuncionesService } from './funciones.service';
import { Globals } from './globales';
import { FiltropornombrePipe } from './filtros/filtropornombre.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ComponentesModule } from './componentes/componentes.module';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import {MaterialModule} from './material/material.module';
import { TuberiasModule } from './tuberias/tuberias.module';
import { AngularFireModule } from '@angular/fire';
import {GoogleMapsModule} from '@angular/google-maps';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ProductCardComponent } from './product-card/product-card.component';

@NgModule({
  declarations: [
    AppComponent
    ,FiltropornombrePipe, ProductCardComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    ComponentesModule
    ,TuberiasModule
    ,MaterialModule
    ,GoogleMapsModule
    ,NgxFileDropModule
    ,AngularFireModule.initializeApp(environment.firebaseConfig)
  ],
  providers: [Globals,FuncionesService],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="WindowLoad" *ngIf="G.div_esperando">
    <div class="WindowLoad_esperando"></div>
</div>
<app-alerta [alerta_ver]="G.alerta_ver" [mensaje]="G.alerta_texto" [tipo]="G.alerta_tipo"></app-alerta>
<app-confirmar [mensaje]="G.confirmar_mensaje"></app-confirmar>
<div class="cabecera" (window:resize)="cambia_ancho($event)" *ngIf="G.ASALTO===0">
    <img class="izquierda margen_derecho_5" src="assets/logo_garayzabal.png" (click)="pantalla='buscar'" style="height: 56px;" title="Inicio">
    <div *ngIf="G.modo_celular" style="width: 130px;display: inline-block;margin-top: 8px;" class="izquierda margen_izquierdo_5 margen_derecho_5"><app-boton [ancho]="130" [alto]="40" [titulo]="'Buscar propiedades'" [Gris]="true" (enter)="pantalla='buscando'"></app-boton></div>
    <div *ngIf="!G.modo_celular" style="width: 100px;display: inline-block;" class="izquierda margen_derecho_5"><app-select-especial [titulo]="'Operación:'" [datos]="G.propiedades_operaciones" [(inputModel)]="filtro_operacion"></app-select-especial></div>
    <div *ngIf="!G.modo_celular" style="width: 150px;display: inline-block;" class="izquierda margen_derecho_5"><app-select-especial [titulo]="'Tipo de Propiedad:'" [datos]="G.propiedades_tipos" [(inputModel)]="filtro_tipo"></app-select-especial></div>
    <div *ngIf="!G.modo_celular && barrios_todos.length>1" style="width: 370px;display: inline-block;" class="izquierda margen_derecho_5"><app-select-especial [titulo]="'Barrio:'" [datos]="barrios_todos" [(inputModel)]="filtro_barrio"></app-select-especial></div>
    <div *ngIf="!G.modo_celular" style="width: 100px;display: inline-block;margin-top: 8px;" class="izquierda margen_izquierdo_5 margen_derecho_5"><app-boton [ancho]="100" [alto]="40" [titulo]="'BUSCAR'" [Gris]="true" (enter)="f_buscar()"></app-boton></div>
    <div *ngIf="pantalla==='viendo_una' && !G.modo_celular" style="width: 150px;display: inline-block;margin-top: 8px;" class="derecha margen_derecho_5">
        <app-boton [ancho]="150" [alto]="40" [titulo]="'VOLVER AL LISTADO'" [Verde]="true" (enter)="pantalla='listado'"></app-boton>
    </div>
    <div *ngIf="pantalla==='viendo_una' && G.modo_celular" style="width: 50px;display: inline-block;margin-top: 8px;" class="derecha margen_derecho_5">
        <app-menu-item [ImagenRegresar]="true" [AlinearDerecha]="true" (click)="pantalla='listado'"></app-menu-item>
    </div>
</div>
<div *ngIf="pantalla!=='listado'" class="div_contenedor_componentes_menu" style="background-image: url('assets/fondo.jpg');">
    <div *ngIf="pantalla==='buscando'">
        <div style="width: 100%;padding: 5px;"><app-select-especial [titulo]="'Operación:'" [datos]="G.propiedades_operaciones" [(inputModel)]="filtro_operacion"></app-select-especial></div>
        <div style="width: 100%;padding: 5px;"><app-select-especial [titulo]="'Tipo de Propiedad:'" [datos]="G.propiedades_tipos" [(inputModel)]="filtro_tipo"></app-select-especial></div>
        <div *ngIf="barrios_todos.length>1" style="width: 100%;padding: 5px;"><app-select-especial [titulo]="'Barrio:'" [datos]="barrios_todos" [(inputModel)]="filtro_barrio"></app-select-especial></div>
        <div style="width: 100%;padding: 5px;"><app-boton [ancho]="100" [alto]="40" [titulo]="'BUSCAR'" [Gris]="true" (enter)="f_buscar()"></app-boton></div>
    
    </div>
    <div *ngIf="pantalla!=='buscando'" style="display: flex;width:100%;height: 100%;align-content: center;align-items: center;">
            <div class="presentacion">
                <h1>Garayzábal Propiedades</h1>
                <p>Somos una inmobiliaria de estilo familiar ubicada en la provincia de Córdoba. Nos especializamos en la comercialización de propiedades para venta y alquiler, destacándonos por nuestra confianza, cercanía y compromiso con cada cliente.</p>
                <h2>¿Por qué elegirnos?</h2>
                <ul>
                    <li>Atención personalizada: Escuchamos tus necesidades y te acompañamos en cada paso.</li>
                    <li>Amplia experiencia en el mercado inmobiliario cordobés.</li>
                    <li>Confianza y seguridad en cada operación.</li>
                </ul>
                <h2>Servicios que ofrecemos</h2>
                <ul>
                    <li>Compra y venta de propiedades urbanas y rurales.</li>
                    <li>Alquiler de casas, departamentos y locales comerciales.</li>
                    <li>Asesoramiento integral en cada operación.</li>
                </ul>
                <div class="contact">
                    <p>En <strong>Garayzábal Propiedades</strong>, te ayudamos a encontrar más que una propiedad: ¡te ayudamos a encontrar tu lugar en el mundo!</p>
                    <p><a href="mailto:contacto@garayzabal.com.ar">Contáctanos</a> para más información.</p>
                </div>
            </div>
    </div>
</div>
<div *ngIf="pantalla==='listado'" class="div_contenedor_componentes_menu" style="background-color: whitesmoke;">
    <app-product-card *ngFor="let item of propiedades"
        [imageUrl]="item.dato.foto_portada_data"
        [precio]="item.moneda.simbolo+' '+Funcion.formatNumber(item.dato.precio,0)"
        [direccion]="item.dato.direccion"
        [barrio]="item.barrio.barrio_nombre"
        [ciudad]="item.ciudad.nombre"
        [m2]="item.dato.m2"
        [m2_construido]="item.dato.m2_construido"
        [ambientes]="item.dato.ambientes"
        [dormitorios]="item.dato.dormitorios"
        [banios]="item.dato.banios"
        [tipo]="item.tipo.nombre"
        (click)="f_mostrar_propiedad(item)">
    </app-product-card>
</div>
<div *ngIf="pantalla==='viendo_una'" class="div_contenedor_componentes_menu" style="background-color: rgb(217, 217, 217); padding-bottom: 20px;">
    <div class="div_linea_viendo_una">
        <div [style.width.px]="f_ancho_viendo_uno()" style="padding-left:10px;margin-top: 10px;">
            <h4 class="izquierda">{{viendo.dato.direccion}}</h4>
        </div>
    </div>
    <div class="div_linea_viendo_una">
        <div [style.width.px]="f_ancho_viendo_uno()" style="padding-left:10px;">
            <h6 class="izquierda">{{viendo.barrio.barrio_nombre}}, {{viendo.ciudad.nombre}}</h6>
        </div>
    </div>
    <div class="div_linea_viendo_una">
        <app-image-carousel *ngIf="fotos.length>0"[images]="fotos" [ancho]="f_ancho_viendo_uno()"></app-image-carousel>
    </div>
    <div class="div_linea_viendo_una">
        <div class="una_descripcion" [style.width.px]="f_ancho_viendo_uno()">
            <div style="width: 100%;">
                <h4 [style.width.px]="f_ancho_viendo_uno()-40" style="border-bottom: solid 1px rgb(181, 181, 181);padding-bottom: 30px;" class="izquierda">Descripción</h4>
            </div>
            <div style="width: 100%;white-space: pre-wrap;word-wrap: break-word;text-align: justify;">
                <p>{{viendo.dato.descripcion}}</p>
            </div>
        </div>
    </div>
    <div class="div_linea_viendo_una" *ngIf="viendo.detalles.length>0">
        <div class="una_descripcion" [style.width.px]="f_ancho_viendo_uno()">
            <div style="width: 100%;">
                <h4 [style.width.px]="f_ancho_viendo_uno()-40" style="border-bottom: solid 1px rgb(181, 181, 181);padding-bottom: 30px;" class="izquierda">Detalle</h4>
            </div>
            <div class="attribute-container detalle" [ngClass]="{'modo_celular':G.modo_celular}">
                <div class="attribute" *ngFor="let item of viendo.detalles" style="font-size: small;">
                  <b style="margin-right: 3px;">{{item.nombre}}</b> {{item.valor}}
                </div>
            </div>
        </div>
    </div>

    <div class="div_linea_viendo_una" *ngIf="viendo.atributos.length>0">
        <div class="una_descripcion" [style.width.px]="f_ancho_viendo_uno()">
            <div style="width: 100%;">
                <h4 [style.width.px]="f_ancho_viendo_uno()-40" style="border-bottom: solid 1px rgb(181, 181, 181);padding-bottom: 30px;" class="izquierda">Características</h4>
            </div>
            <div class="attribute-container" [ngClass]="{'modo_celular':G.modo_celular}">
                <div class="attribute" *ngFor="let item of viendo.atributos">
                  <span class="checkmark">✔</span> {{item}}
                </div>
            </div>
        </div>
    </div>

</div>
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';

import { AlertaComponent } from './alerta/alerta.component';

import { NgSelectModule } from '@ng-select/ng-select';

import { TextoComponent } from './texto/texto.component';
import { FechaComponent } from './fecha/fecha.component';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NumeroComponent } from './numero/numero.component';
import { BotonComponent } from './boton/boton.component';
import { TildarComponent } from './tildar/tildar.component';
import { ConfirmarComponent } from './confirmar/confirmar.component';
import { TuberiasModule } from '../tuberias/tuberias.module';
import { SelectComunComponent } from './select-comun/select-comun.component';
import { SelectEspecialComponent } from './select-especial/select-especial.component';
import { SpanComponent } from './span/span.component';
import { CargarImagenComponent } from './cargar-imagen/cargar-imagen.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { VerFotosComponent } from './ver-fotos/ver-fotos.component';
import { FechaDesdeHastaComponent } from './fecha-desde-hasta/fecha-desde-hasta.component';
import { NumeroDesdeHastaComponent } from './numero-desde-hasta/numero-desde-hasta.component';
import { ImagenMostrarComponent } from './imagen-mostrar/imagen-mostrar.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { TextareaComponent } from './textarea/textarea.component';
import { ImagenesComponent } from './imagenes/imagenes.component';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';

@NgModule({
  declarations: [AlertaComponent, TextoComponent, FechaComponent, NumeroComponent, BotonComponent, TildarComponent, ConfirmarComponent, SelectComunComponent, SelectEspecialComponent, SpanComponent, CargarImagenComponent, VerFotosComponent
    ,FechaDesdeHastaComponent
    ,NumeroDesdeHastaComponent
    ,ImagenMostrarComponent
    ,MenuItemComponent
    ,TextareaComponent
    ,ImagenesComponent, ImageCarouselComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BsDatepickerModule.forRoot(),TuberiasModule,NgSelectModule,NgxFileDropModule
    ,MatDatepickerModule,MatNativeDateModule,MatInputModule,ReactiveFormsModule
],
  exports: [
    AlertaComponent
    ,TextoComponent
    ,FechaComponent
    ,NumeroComponent
    ,BotonComponent
    ,TildarComponent
    ,ConfirmarComponent
    ,SelectComunComponent
    ,SelectEspecialComponent
    ,SpanComponent
    ,CargarImagenComponent
    ,VerFotosComponent
    ,FechaDesdeHastaComponent
    ,NumeroDesdeHastaComponent
    ,ImagenMostrarComponent
    ,MenuItemComponent
    ,TextareaComponent
    ,ImagenesComponent
    ,ImageCarouselComponent
  ]
})
export class ComponentesModule { }

<div [style.width.px]="ancho" class="div-contenedor-imagen" [style.background-color]="color_fondo" [style.border]="borde" [style.border-radius.px]="borde_radio">
    <span *ngIf="titulo!==''" class="titulo">{{titulo}}</span>
    <div style="display: flex;align-items: top; width:100%">
        <div [style.max-width.px]='div_imagen_ancho' [style.max-height.px]="imagen_alto_maximo" class="mi-imagen" *ngIf="imagen_original!==''">
            <img [style.max-width.px]='div_imagen_ancho' [style.max-height.px]="imagen_alto_maximo" [src]="imagen_original" (click)="achicar(imagen_original)">
        </div>
        <div *ngIf="ImagenCargada" [style.max-width.px]='div_imagen_ancho' [style.max-height.px]="imagen_alto_maximo" class="mi-imagen" [ngClass]="{'mi-imagen-desplazada':imagen_original!==''}">
            <img id="borrar" (click)="f_sacar_imagen()" src="assets/x-square.svg">
            <img id="fotocargada" [style.max-width.px]='div_imagen_ancho' [style.max-height.px]="imagen_alto_maximo" [src]="cardImageBase64">
        </div>
        <div class="mi-selector" [style.width.px]='div_imagen_ancho' [ngClass]="{'mi-imagen-desplazada':imagen_original!==''}" *ngIf="!ImagenCargada">
            <ngx-file-drop class="etiqueta_subir_archivo" [multiple]="acepta_multiples" dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)">
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                    <b style="color: black;" *ngIf="!acepta_multiples">Arrastre hasta aquí su archivo o presione el botón para seleccionar</b>
                    <b style="color: black;" *ngIf="acepta_multiples">Arrastre hasta aquí los archivos o presione el botón para seleccionar</b>
                    <button type="button" (click)="openFileSelector()">Buscar y seleccionar</button>
                </ng-template>
            </ngx-file-drop>
        </div>
        <canvas #canvas style="visibility: hidden;"></canvas>
    </div>

    <span *ngIf="ImagenError!==''">{{ImagenError}}</span>
</div>
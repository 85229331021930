<div *ngIf="G.confirmar_ver" style="position: fixed;top: 0px;left: 0px;height: 100vh;width: 100vw;margin: 0;z-index: 3201;filter: alpha(opacity=65);-moz-opacity: 65;opacity: 0.65;background-color: gray;">
</div>
<div class="Alerta" *ngIf="G.confirmar_ver">
    <div [ngClass]="{'sombra-negra':G.confirmar_tipo===1}">
        <span>{{mensaje}}</span>
        <hr>
        <button *ngFor="let item of G.confirmar_opciones" type="button" (click)="retornar(item.valor)" class="btn" [ngClass]="{'btn-primary':item.tipo===1,'btn-danger':item.tipo===2,'btn-success':item.tipo===3}">{{item.mensaje}}</button>
        <!--
            <app-boton *ngFor="let item of G.confirmar_opciones" [Tipo]="item.tipo" [titulo]="item.mensaje" (click)="retornar(item.valor)"></app-boton>
            -->
    </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { Globals } from '../../globales';
import { FuncionesService } from '../../funciones.service';

@Component({
  selector: 'app-confirmar',
  templateUrl: './confirmar.component.html',
  styleUrls: ['./confirmar.component.scss','../../bootstrap.css']
})
export class ConfirmarComponent implements OnInit {
  @Input() mensaje = '';
  G: Globals;
  constructor(variables: Globals,private Funcion: FuncionesService) {this.G=variables;
  }

  ngOnInit() {}
  retornar(valor) {
    this.Funcion.Confirmar$.emit(valor);
    this.G.confirmar_ver=false;
  }

}

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FastAverageColor } from 'fast-average-color';


@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent implements OnInit, OnDestroy {
  @Input() images: string[] = []; // Lista de URLs de imágenes recibida como propiedad
  @Input() interval: number = 3000; // Intervalo de cambio en milisegundos, por defecto 3000ms
  @Input() ancho: number = 800;

  currentIndex: number = 0; // Índice actual de la imagen
  backgroundColor: string = '#f9f9f9'; // Color de fondo inicial
  intervalId: any; // Almacenará el ID del intervalo para detenerlo cuando se desee
  private fac = new FastAverageColor();
  ngOnInit(): void {
    this.startAutoSlide();
    this.updateBackgroundColor();
  }

  ngOnDestroy(): void {
    this.stopAutoSlide();
  }

  // Función para iniciar el desplazamiento automático
  startAutoSlide(): void {
    this.intervalId = setInterval(() => {
      this.nextSlide();
    }, this.interval);
  }

  // Función para detener el desplazamiento automático
  stopAutoSlide(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  // Cambiar a la siguiente imagen
  nextSlide(): void {
    this.currentIndex = (this.currentIndex + 1) % this.images.length;
    this.updateBackgroundColor();
  }

  // Cambiar a la imagen anterior
  prevSlide(): void {
    this.currentIndex =
      (this.currentIndex - 1 + this.images.length) % this.images.length;
      this.updateBackgroundColor();
  }

  updateBackgroundColor() {
    const img = new Image();
    img.src = this.images[this.currentIndex];
    img.crossOrigin = 'Anonymous'; // Necesario para evitar problemas con CORS

    img.onload = () => {
      const color = this.fac.getColor(img);
      this.backgroundColor = color.hex; // Usa el color en formato HEX
    };

    img.onerror = () => {
      this.backgroundColor = '#f9f9f9'; // Color predeterminado si ocurre un error
    };
  }

  goToSlide(index: number) {
    this.currentIndex = index;
    this.updateBackgroundColor();
  }


}
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { DatePipe } from '@angular/common';

@Injectable()
export class Globals {
  /* variables */
  sino=[{id:0,nombre:'NO'},{id:1,nombre:'SI'}];
  MESES=[{id:1,nombre:'Enero'},{id:2,nombre:'Febrero'},{id:3,nombre:'Marzo'},{id:4,nombre:'Abril'},{id:5,nombre:'Mayo'},{id:6,nombre:'Junio'},{id:7,nombre:'Julio'},{id:8,nombre:'Agosto'},{id:9,nombre:'Septiembre'},{id:10,nombre:'Octubre'},{id:11,nombre:'Noviembre'},{id:12,nombre:'Diciembre'}];
  URL = '';
  appVersion = '';
  ModoProduccion=false;
  alerta_ver=false;
  alerta_texto='';
  alerta_tipo=0;
  confirmar_ver=false;
  confirmar_tipo=1;
  confirmar_valor=0;
  confirmar_opciones=[];
  confirmar_mensaje='';
  div_esperando = false;
  ASALTO = 0;
  viendo=-1;
  ancho=1000;
  alto=1000;
  uso_horario='GTM-3';
  /* Configuración de Google Maps*/
  gps_inicial = {lat: -32.81124987956366, lng: -63.244417905807495};
  gps_mi_posicion = {lat: -32.81124987956366, lng: -63.244417905807495};
  gps_etiqueta = { color: 'blue', text: 'Mi ubicación'};
  gps_zoom = 15;
  empresa=0;
  modo_celular=false;

  monedas_seleccionables=[
    {id:1,nombre:'Pesos',singular:'peso',codigo_afip:'PES',simbolo:'$',decimales:2,nombre_completo:'Pesos Argentinos'}
    ,{id:2,nombre:'Dólares',singular:'dólar',codigo_afip:'DOL',simbolo:'u$s',decimales:4,nombre_completo:'Dólar Estadounidense'}
  ];

  propiedades_tipos=[
    {id:0,nombre:'--Todas--'}
    ,{id:1,nombre:'Casa',pide_m2_construido:true,es_vivienda:true,pide_antiguedad:true,pide_banios:true,pide_cochera:true}
    ,{id:2,nombre:'Departamento',pide_m2_construido:true,es_vivienda:true,pide_antiguedad:true,pide_banios:true,pide_cochera:true}
    ,{id:3,nombre:'Terreno/Lote',pide_m2_construido:false,es_vivienda:false,pide_antiguedad:false,pide_banios:false,pide_cochera:false}
    /*
    ,{id:4,nombre:'Oficina',pide_m2_construido:true,es_vivienda:false,pide_antiguedad:true,pide_banios:true,pide_cochera:true}
    ,{id:5,nombre:'Local',pide_m2_construido:true,es_vivienda:false,pide_antiguedad:true,pide_banios:true,pide_cochera:true}
    ,{id:6,nombre:'Galpón',pide_m2_construido:true,es_vivienda:false,pide_antiguedad:true,pide_banios:true,pide_cochera:true}
    ,{id:7,nombre:'Depósito',pide_m2_construido:true,es_vivienda:false,pide_antiguedad:true,pide_banios:true,pide_cochera:true}
    ,{id:8,nombre:'Campo',pide_m2_construido:false,es_vivienda:false,pide_antiguedad:false,pide_banios:false,pide_cochera:false}
    ,{id:9,nombre:'Industrial',pide_m2_construido:true,es_vivienda:false,pide_antiguedad:true,pide_banios:true,pide_cochera:true}
    ,{id:10,nombre:'Garage',pide_m2_construido:false,es_vivienda:false,pide_antiguedad:true,pide_banios:false,pide_cochera:false}
    */
  ];

  propiedades_barrios=[
    {id:0,nombre:'--Todos--'}
    ,{id:1,nombre:'Abierto'}
    ,{id:2,nombre:'Cerrado'}
    ,{id:3,nombre:'Country'}
    ,{id:4,nombre:'Condominio'}
  ];

  propiedades_operaciones=[
    {id:0,nombre:'--Todas--'}
    ,{id:1,nombre:'Compra'}
    ,{id:2,nombre:'Alquiler'}
  ];

  propiedades_living_comedor=[
    {id:0,nombre:''}
    ,{id:4,nombre:'Living y Comedor'}
    ,{id:1,nombre:'Solo Living'}
    ,{id:2,nombre:'Solo Comedor'}
    ,{id:3,nombre:'Living-Comedor'}
  ];

  propiedades_cocina=[
    {id:1,nombre:'Cocina Comedor'}
    ,{id:2,nombre:'Cocina Separada'}
  ];

  propiedades_gas=[
    {id:0,nombre:'No tiene'}
    ,{id:1,nombre:'Natural'}
    ,{id:2,nombre:'Envasado'}
  ];

  propiedades_calefaccion=[
    {id:0,nombre:'No tiene'}
    ,{id:1,nombre:'Calefactores'}
    ,{id:2,nombre:'Central con radiador'}
    ,{id:3,nombre:'Loza Radiante'}
    ,{id:4,nombre:'Split'}
  ];

  propiedades_aire=[
    {id:0,nombre:'No tiene'}
    ,{id:1,nombre:'Split'}
    ,{id:2,nombre:'Central'}
  ];

  propiedades_mascotas=[
    {id:0,nombre:''}
    ,{id:1,nombre:'Acepta Cualquiera'}
    ,{id:2,nombre:'Animales pequeños'}
    ,{id:20,nombre:'Prohibido'}
  ];


}

export class Fechas_desde_hasta {
  f2=new DatePipe('en-Us').transform(new Date(), 'yyyy-MM-dd','GTM-3');
  desde=true;
  desde_fecha=new Date(parseFloat(this.f2.substring(0,4)),parseFloat(this.f2.substring(5,7))-1,parseFloat(this.f2.substring(8,10)));
  hasta=false;
  hasta_fecha=new Date(parseFloat(this.f2.substring(0,4)),parseFloat(this.f2.substring(5,7))-1,parseFloat(this.f2.substring(8,10)));
  ver_filtro_desde=true;
  ver_filtro_hasta=true;
  titulo_desde='';
  titulo_hasta='';
  constructor(desde=null,desde_fecha=null,hasta=null,hasta_fecha=null,ver_filtro_desde=true,ver_filtro_hasta=true,titulo_desde=null,titulo_hasta=null) {
    this.ver_filtro_desde=ver_filtro_desde;
    this.ver_filtro_hasta=ver_filtro_hasta;
    if (ver_filtro_desde && desde!==null) this.desde=desde;
    if (ver_filtro_hasta && hasta!==null) this.hasta=hasta;
    if (!ver_filtro_desde) { if (titulo_desde!==null) { this.titulo_desde=titulo_desde; } else { this.titulo_desde='Desde:';}}
    if (!ver_filtro_hasta) { if (titulo_hasta!==null) { this.titulo_hasta=titulo_hasta; } else { this.titulo_hasta='Hasta:';}}
    if (desde_fecha!==null) this.desde_fecha=desde_fecha;
    if (hasta_fecha!==null) this.hasta_fecha=hasta_fecha;
  }
  f_mes_anterior() {
    let f=new Date();
    let mes_mio=f.getMonth()+1;
    let mes=f.getMonth();
    let anio=f.getFullYear();
    if (mes===0) {
      anio--;
      mes=11;
      mes_mio=12;
    } else {
      mes--;
      mes_mio--;
    }
    if (mes_mio===1 || mes_mio===3 || mes_mio===5 || mes_mio===7 || mes_mio===8 || mes_mio===10 || mes_mio===12) {
      this.hasta_fecha=new Date(anio,mes,31);
    } else if (mes_mio===4 || mes_mio===6 || mes_mio===9 || mes_mio===11) {
      this.hasta_fecha=new Date(anio,mes,30);
    } else if (anio/4===this.redondear(anio/4,0)) {
      if (anio/100===this.redondear(anio/100,0)) {
        this.hasta_fecha=new Date(anio,mes,28);
      } else {
        this.hasta_fecha=new Date(anio,mes,29);
      }
    } else {
      this.hasta_fecha=new Date(anio,mes,28);
    }
    this.desde_fecha=new Date(anio,mes,1);
    this.desde=true;
    this.hasta=true;
  }
  redondear(valor,decimales=2) { const multiplicar=1*Math.pow(10,decimales); return Math.round(valor*multiplicar)/multiplicar;}
}

export class Numeros_desde_hasta {
  ver_uno_solo=false;
  uno_solo=false;
  desde_ver=true;
  desde_numero=0;
  hasta_ver=false;
  hasta_numero=0;
  decimales=0;
  numero_centrado=true;
  ver_filtro_desdehasta=true;
  titulo_desde='Desde:';
  titulo_hasta='Hasta:';
}


import { NgModule } from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import {MatNativeDateModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({
  exports: [
    MatInputModule
    ,MatDialogModule
    ,MatIconModule
    ,MatNativeDateModule
    ,MatFormFieldModule
  ],
  declarations: []
})
export class MaterialModule {}


import {
  Component, Input, Output, EventEmitter, ViewChild, ElementRef,
  forwardRef, OnChanges, SimpleChanges
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validators
} from '@angular/forms';
import { FuncionesService } from '../../funciones.service';
import { NgxFileDropEntry,FileSystemDirectoryEntry,FileSystemFileEntry } from 'ngx-file-drop';
import { OnInit } from '@angular/core';


@Component({
  selector: 'app-cargar-imagen',
  templateUrl: './cargar-imagen.component.html',
  styleUrls: ['./cargar-imagen.component.scss','../../bootstrap.css'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CargarImagenComponent),
        multi: true
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => CargarImagenComponent),
        multi: true
    }
  ]
})
export class CargarImagenComponent implements ControlValueAccessor, OnChanges, OnInit {
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('fotocargada') fotocargada: ElementRef;
  @Input() ancho=600;
  @Input() altura_maxima=0;
  @Input() titulo='';
  @Input() color_fondo='';
  /* @Input() tamanio_maximo = 10485760;*/
  @Input() ancho_maximo =600; /* de la imagen */
  @Input() alto_maximo =600; /* de la imagen */
  @Input() borde = '';
  @Input() borde_radio = 0;
  @Input() solo_carga = false;
  @Input() acepta_multiples = false;
  @Output() inputModelChange = new EventEmitter<any>();
  ImagenError: string;
  ImagenCargada=false;
  cardImageBase64: string;
  @Input() imagen_original='';
  div_imagen_ancho=this.ancho-10;
  imagen_ancho_maximo=50;
  imagen_alto_maximo=this.ancho-10;

  img = document.createElement ('img');

  public files: NgxFileDropEntry[] = [];
  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.ImagenError = null;
              const reader = new FileReader();
              reader.onload = (e: any) => {
                  const image = new Image();
                  image.src = e.target.result;
                  image.onload = rs => {
                      this.img.src = e.target.result;
                      const imgBase64Path = e.target.result;
                      this.cardImageBase64 = imgBase64Path;
                      this.achicar(this.cardImageBase64);
                  };
              };
              reader.readAsDataURL(file);
        });
      }
    }
  }
  f_sacar_imagen() {
    this.cardImageBase64 = '';
    this.ImagenCargada = false;
    this.emitir_valor(false,'');
  }

  constructor(
    public Funcion: FuncionesService
  ) {
  }

  ngOnInit(): void {
    this.inicializar();
  }

  writeValue(value: number) {
  }

  registerOnChange(fn: any) {
  }

  registerOnTouched(fn: any) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.inicializar();
  }

  OnInit() {
    console.log(this.div_imagen_ancho);
    this.canvas.nativeElement.width=0;
    this.canvas.nativeElement.height=0;
  }

  inicializar() {
    if (this.solo_carga) { this.imagen_original=''; }
    this.div_imagen_ancho=this.ancho-10;
    if (this.solo_carga) {
      this.imagen_alto_maximo=this.altura_maxima;
    } else {
      if (this.imagen_original!=='') { this.div_imagen_ancho=(this.ancho/2)-10; }
      this.imagen_ancho_maximo=this.div_imagen_ancho;
      this.imagen_alto_maximo=this.div_imagen_ancho;
        if (this.altura_maxima>0) {
        if (this.titulo==='') {
          this.imagen_alto_maximo=this.altura_maxima-15;
        } else {
          this.imagen_alto_maximo=this.altura_maxima-40;
        }
        if (this.imagen_ancho_maximo>this.imagen_alto_maximo) { this.imagen_ancho_maximo=this.imagen_alto_maximo; } else if (this.imagen_alto_maximo>this.imagen_ancho_maximo) { this.imagen_alto_maximo=this.imagen_ancho_maximo; }
      }
    }
}

  emitir_valor(pcargada,pvalor) {
      setTimeout(() => this.inputModelChange.emit({cargada:pcargada,imagen:pvalor})  );
  }

  achicar(imagen) {
    this.img.src=imagen;
    let width = this.img.width;
    let height = this.img.height;
    if (width > height) {
      if (width > this.ancho_maximo) {
        height *= this.ancho_maximo / width;
        width = this.ancho_maximo;
      }
    } else {
      if (height > this.alto_maximo) {
        width *= this.alto_maximo / height;
        height = this.alto_maximo;
      }
    }
    width=this.Funcion.redondear(width,0);
    height=this.Funcion.redondear(height,0);
    if (width>0) {
      this.canvas.nativeElement.width = width;
      this.canvas.nativeElement.height = height;
      const ctx = this.canvas.nativeElement.getContext('2d');
      ctx.drawImage(this.img, 0, 0, width, height);
      if (!this.solo_carga) {
        this.ImagenCargada=true;
        this.cardImageBase64=this.canvas.nativeElement.toDataURL('image/jpeg');
      }
      this.emitir_valor(true,this.canvas.nativeElement.toDataURL('image/jpeg'));
      this.canvas.nativeElement.width=0;
      this.canvas.nativeElement.height=0;
    }
  }
}
